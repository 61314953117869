import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import HubspotForm from "components/hubspot"
import Container from "components/container"

const StyledSubscription = styled.section`
  padding: 68px 0;

  ${breakpoint.medium`
    padding: 104px 0;
  `}

  .join {
    font-size: 14px;
    line-height: 1.3em;
    padding: 0 30px 30px;
  }

  .google__privacy {
    font-size: 12px;
    line-height: 1.4em;
    margin: 12px;
    color: ${colors.graySelect};
  }

  a {
    color: ${colors.orange};

    &:hover {
      color: ${colors.purpleDark};
    }
  }

  .bottomText {
    text-align: center;
    margin-top: 20px;
  }

`

const SubscriptionForm = () => {

  return (
    <StyledSubscription>
      <Container data-aos="fade-up">
        <div>
          <HubspotForm
            formId='b0861197-29fb-46ef-a559-ef2473cc9e92'
          />
        </div>
      </Container>
    </StyledSubscription>
  )
}

export default SubscriptionForm
